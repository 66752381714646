import React from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import FilmTimes from "./FilmTimes";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function Programma() {
	const { id } = useParams();
	const [programma, setProgramma] = useState(false);
	const [error, setError] = useState("");
	const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

	React.useEffect(() => {
		async function fetchData() {
			axios
				.get(
					`https://kritsite-cms-mxa7oxwmcq-ez.a.run.app/api/programmas/${id}?populate=*`
				)
				.then((res) => {
					setError("");
					setProgramma(res.data.data);
				})
				.catch((e) => {
					const { status } = e.response;
					if (status === 404) {
						setProgramma(false);
						setError("Title not found");
						return;
					}
					setError("Something went wrong, try again later");
				});
		}
		fetchData();
		window.scrollTo(0, 0)
	}, [id]);

	return (
		<React.Fragment>
			<div className="body-container">
				{programma && (
					<div className="programma-content">
						<img
							className='banner-image'
							src={
								programma.attributes.cover.data.attributes.formats
									.small.url
							}
							alt={"Foto van Programma " + programma.attributes.titel}
						></img>
						<div className='programma-details'>
							<h2
								className='film-titel'
								key={programma.attributes.title}
							>
								{programma.attributes.title}
							</h2>
							{ }
							{/* Loop over films, display title + year (and link) */}
							{/* Programma description */}
							{programma.attributes.films.data.map(
								(programmafilm) => (
									<Link
										key={programmafilm.attributes.productionid}
										to={
											"/films/" +
											programmafilm.attributes.productionid
										}
									>
										<p className='programma-subtekst'>
											{programmafilm.attributes.titel +
												" (" +
												programmafilm.attributes.jaar +
												")"}
										</p>
									</Link>
								)
							)}
						</div>
						{JSON.parse(programma.attributes.description).blocks.map(
							(block) => {
								return (
									<p
										className='tekst'
										key={block.id}
										dangerouslySetInnerHTML={{
											__html: block.data.text,
										}}
									/>
								);
							}
						)}
						{programma.attributes.films.data.map((programmafilm) => (
							<div key={programmafilm.attributes.productionid} className="progfilm-container">
								<img
									className='progfilm-banner-image'
									src={
										programmafilm.attributes.still.data
											.attributes.formats.medium.url
									}
									alt={
										"Filmstill van " +
										programmafilm.attributes.titel
									}
								></img>
								<div className='film-details'>
									<Link
										to={
											"/films/" +
											programmafilm.attributes.productionid
										}
									>
										<h2
											className='film-titel'
											key={programmafilm.attributes.titel}
										>
											{" "}
											{programmafilm.attributes.titel}
										</h2>
									</Link>
									<p className='film-subtekst'>
										{programmafilm.attributes.regie} -{" "}
										{programmafilm.attributes.jaar} -{" "}
										{programmafilm.attributes.speelduur}
										{isMobile && <br />}
										{!isMobile && " - "}
										{
											programmafilm.attributes.gesproken
										}{" - "}
										{
											programmafilm.attributes.ondertitels
										}

									</p>
								</div>
								{/* Description */}
								{/* {JSON.parse(
									programmafilm.attributes.beschrijving
								).blocks.map((block) => {
									return (
										<p className='tekst' key={block.id}>
											{block.data.text}
										</p>
									);
								})} */}
								{/* Timetable */}
								<FilmTimes
									slug={programmafilm.attributes.productionid}
								></FilmTimes>
								{JSON.parse(programmafilm.attributes.beschrijving).blocks.map(
									(block) => {
										return (
											<p
												className='tekst'
												key={block.id}
												dangerouslySetInnerHTML={{
													__html: block.data.text,
												}}
											/>
										);
									}
								)}
							</div>
						))}

						{/* <FilmTimes slug={id}></FilmTimes> */}
					</div>
				)}
				{error && <p className='error'>{error}</p>}
			</div>
		</React.Fragment>
	);
}

// function isOndertitel(taal) {
// 	if (taal === "Nederlands") {
// 		return "NL";
// 	} else if (!taal || taal === "Geen"){
// 		return "Geen";
// 	} else {
// 		return taal + "e";
// 	}
// }

// function isGesproken(taal) {
// 	if (taal === "Nederlands") {
// 		return "NL";
// 	} else if (!taal || taal === "Geen"){
// 		return "Niet";
// 	} else {
// 		return taal;
// 	}
// }
