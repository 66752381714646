// https://kritsite-cms-mxa7oxwmcq-ez.a.run.app/api/programmas?populate=deep

// import { useMediaQuery } from "react-responsive";
import React from "react";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

export default function Programmas() {
	const [programmas, setProgrammas] = useState([]);
	const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

	React.useEffect(() => {
		axios
			.get(
				"https://kritsite-cms-mxa7oxwmcq-ez.a.run.app/api/programmas?populate=*&sort[0]=createdAt:desc"
			)
			.then((response) => {
				setProgrammas(response.data.data);
			});
	}, []);

	return (
		<div className='body-container'>
			{!isMobile && <h2 className="desktop-titel desktop-programmas-titel">Filmprogramma's</h2>}
			{programmas.map((programma) => (
				<div className='progs-container' key={programma.attributes.slug}>
					<Link className="progs-image" to={programma.attributes.slug}>
						<img

							src={
								programma.attributes.cover.data.attributes
									.formats.small.url
							}
							alt={
								"Foto voorprogramma" +
								programma.attributes.title
							}
						/>
					</Link>
					<div className='progs-details'>
						<Link to={programma.attributes.slug}>
							<h3 className='progs-details-title'>
								{programma.attributes.title}
							</h3>
						</Link>
						{programma.attributes.films.data.length <= 5 &&
							programma.attributes.films.data.map(
								(programmafilm) => (
									<Link
										className='progsfilms-details'
										to={
											"/films/" +
											programmafilm.attributes.productionid
										}
										key={programmafilm.attributes.productionid}
									>
										<p>{programmafilm.attributes.titel}</p>
									</Link>
								)
							)}

						{programma.attributes.films.data.length > 5 &&
							programma.attributes.films.data.slice(0, 6).map(
								(programmafilm) => (
									<Link
										className='progsfilms-details'
										to={
											"/films/" +
											programmafilm.attributes.productionid
										}
										key={programmafilm.attributes.productionid}
									>
										<p>{programmafilm.attributes.titel}</p>
									</Link>
								)
							)
						}

						{programma.attributes.films.data.length > 6 &&
							(<Link className='progsfilms-details' to={programma.attributes.slug}>...</Link>)
						}

					</div>
				</div>
			))}
		</div>
	);
}
